@import "../../styles/helpers";

.newsletter {
    background: #f3f3f3;
    padding: 20px; }

.newsletter_50 {
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    background: white;
    @include dark {
        background: $n1; } }

.newsletter_p {
    color: 70%;
    font-size: 18px;
    margin-bottom: 20px; }

.loader_center {
    display: flex;
    justify-content: center;
    align-items: center; }
