// common styles
@import "./reset";
@import "./helpers";
@import "./common";
@import "./toast";


// global classes
@import "./blocks/section";
@import "./blocks/container";
@import "./blocks/title";
@import "./blocks/info";
@import "./blocks/button";
@import "./blocks/status";
@import "./blocks/category";
@import "./blocks/slick";
@import "./blocks/stage";
@import "./blocks/dateRangePicker";
@import "./blocks/skeleton";

[class^="section"]#ceremonies,
[class^="section"]#articles,
[class^="section"]#retreats, {
    margin-bottom: 0;
    padding-top: 100px; }

[class^="section"]#retreats.withContent {
    min-height: 800px; }

.cursorPointer {
    cursor: pointer; }
