@import "../../styles/helpers";

.form {
    position: relative; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 24px;
    background: none;
    border: 2px solid $n6;
    @include poppins;
    @include caption-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n8; }
    &:focus {
        border-color: $n4; }
    @include placeholder {
        color: $n4; } }

.btn {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $p1;
    transition: background .2s;
    padding-bottom: 2px;
    svg {
        fill: $n8; }
    &:hover {
        background: darken($p1, 10); } }

.btnBrown {
    background:#6f6b2c {} }

.big {
    .input {
        height: 72px;
        padding: 0 72px 0 22px;
        border-radius: 36px;
        border: 2px solid $n7;
        font-size: 16px;
        @include placeholder {
            color: $n5; }
        &:focus {
            border-color: $n5; }
        @include dark {
            border-color: $n3;
            &:focus {
                border-color: $n5; } } }
    .btn {
        top: 16px;
        right: 16px;
        bottom: 16px;
        width: 40px;
        height: 40px;
        svg {
            width: 20px;
            height: 20px; } } }
