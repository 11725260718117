@import "styles/helpers";

.notificationModal>div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;

    svg {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.8rem; } }

.successIcon {
    fill: $p4; }

.warningIcon {
    fill: $s3; }
