@import "styles/helpers";

.mapsMainContainer {
    width: 100%;
    height: 500px;
    border-radius: 1.5rem;
    overflow: hidden;
    border: 1px solid $n6; }

.mapMarkerContainer {
    position: absolute;
    transform: translate(-90%, -90%);
    background: $n7;
    padding: 0.7rem;
    border-radius: 50%;
    box-shadow: 0px 0px 12px -8px $n2;
    @include dark {
        background: $n2; }
    &:hover {
        .address {
            display: block; } }

    .address {
        position: absolute;
        transform: translate(35%, 0%);
        top: 0;
        left: 0;
        min-width: 10rem;
        padding: 0.6rem;
        background: $n7;
        border-radius: 0.6rem;
        font-size: 0.9rem;
        display: none;
        @include dark {
            color: $n7;
            background: $n2; } } }

.locationMarker {
    width: 1.8rem;
    height: 1.8rem;
    @include dark {
        fill: $n7; } }

.mapsSkeletonContainer {
    width: 100%;
    height: 100%;
    padding: 1rem;
    position: relative;

    .mapsMarkerSkeleton {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 3.4rem;
        height: 3.4rem;
        border-radius: 99999px;

        &:nth-child(1) {
            top: 20%;
            left: 40%; }
        &:nth-child(2) {
            top: 10%;
            left: 20%; }
        &:nth-child(3) {
            top: 60%;
            left: 20%; }
        &:nth-child(4) {
            top: 28%;
            left: 80%; }
        &:nth-child(5) {
            top: 80%;
            left: 50%; } } }
