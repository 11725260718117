@import "../../styles/helpers";

html {
    scroll-behavior: smooth; }

.registerContainer {
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerTitle {
        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 1;
        letter-spacing: -.02em;
        color: $n1;
        text-shadow: 0px 0px 2px $n4;
        text-transform: uppercase;
        @include dark {
            color: $n7;
            text-shadow: 0px 0px 3px $n5; } } }

.capitalHeading {
    text-transform: uppercase;
    margin: 0 auto;
    display: block;
    text-align: center; }

.title {
    text-align: center;
    margin: 2rem auto 1rem;
    color: $n2;
    @include dark {
        color: $n8; } }

.loader {
    margin: 0 16px 0 5px;
    transform: scale(1.4);
    margin-top: 2rem;
    margin-bottom: 2rem; }

.successIcon {
    margin-right: .5rem;
    fill: $p4; }

.message {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    align-items: center; }

.icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    &.success {
        fill: $p4; }
    &.warning {
        fill: $s3; }
    &.error {
        fill: $e1; } }

.registrationSuccessContainer {
    min-height: calc(100vh - 224px);
    display: flex;
    flex-direction: column;
    justify-content: center; }


.redirectLink {
    text-align: center;
    a {
        color: $n1;
        text-decoration: underline;
        @include dark {
            color: $white; } } }

.stepperMain {
    position: relative;

    .stepperContainer {
        display: flex;
        width: 450px;
        margin: 20px auto 0;
        justify-content: space-around;
        div {}
        p {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center; }
        .first {
            background: $p1;
            color: $white; }
        .second {
            background: $n6;
            color: $p1;
            font-weight: 500;
            @include dark {
                color: $p1;
                font-weight: 500; } } }
    .line {
        height: 2px;
        background: grey;
        width: 50%;
        margin: 0 auto;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1; } }




