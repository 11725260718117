@import "../../styles/helpers";

html {
    scroll-behavior: smooth; }

.registerContainer {
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center; }

.capitalHeading {
    text-transform: uppercase;
    margin: 0 auto;
    display: block;
    text-align: center; }

.title {
    text-align: center;
    margin: 2rem auto 1rem;
    color: $n2;
    @include dark {
        color: $n8; } }


.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center; }

.loader {
    margin: 0 16px 0 5px;
    transform: scale(1.4);
    margin-top: 2rem;
    margin-bottom: 2rem; }

.successIcon {
    margin-right: .5rem;
    fill: $p4; }

.message {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    font-size: 1.5rem;
    margin-bottom: 1.5rem; }

.icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    &.success {
        fill: $p4; }
    &.warning {
        fill: $s3; }
    &.error {
        fill: $e1; } }
