@import "../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba($n1,.3);
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 32px 16px; }
  @include dark {
    background: rgba($n2,.8); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: 100%;
  max-width: 554px;
  margin: auto;
  background: $n8;
  border-radius: 16px;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
  z-index: 2;
  @include dark {
    background: $n1; }
  & > div {
    padding: 72px 96px;
    overflow-y: auto;
    @include m {
      padding: 32px 24px; } } }

.close {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 32px;
  height: 32px;
  background: $n8;
  border-radius: 50%;
  box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.1);
  font-size: 0;
  svg {
    fill: $n4;
    transition: transform .2s; }
  @include dark {
    border-color: $n3;
    background: $n2;
    svg {
      fill: $n4; } }
  &:hover {
    svg {
      transform: rotate(90deg); } } }
