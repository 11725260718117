@import "styles/helpers";

.header {
    position: relative;
    z-index: 10;
    padding: 24px 0;
    .title {
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1;
        letter-spacing: -.02em;
        color: $n1;
        text-shadow: 0px 0px 2px $n4;
        text-transform: uppercase;
        @include dark {
            color: $n7;
            text-shadow: 0px 0px 3px $n5; } }
    @include m {
        padding: 48px 0 24px; }
    &.wide {
        .container {
            max-width: 100%; } } }

.headerBorder {
    box-shadow: inset 0 -1px 0 0 $n6;
    @include dark {
        box-shadow: inset 0 -1px 0 0 $n2; } }

.container {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.headerSection {
    display: flex;
    align-items: center; }

.logo {
    margin-right: 40px;
    @include d {
        margin-right: 32px; }
    @include m {
        position: relative;
        z-index: 15;
        margin-right: auto; }
    img {
        width: 106px; } }

.wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 40px;
    border-left: 1px solid $n6;
    @include d {
        padding-left: 32px; }
    @include t {
        padding-left: 0;
        border: none; }
    @include m {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        flex-direction: column;
        align-items: stretch;
        height: 100vh;
        min-height: auto;
        padding: 132px 32px 48px;
        background: $n8;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
        @include dark {
            background: $n1; }
        &.active {
            visibility: visible;
            opacity: 1;
            pointer-events: all; } }
    @include dark {
        border-color: $n2; }
    & > div {
        &:first-child {
            @include t {
                margin-right: auto; }
            @include m {
                margin: 0; } } } }

.dropdown {
    margin-right: auto; }

.link {
    margin-left: auto;
    @include button-2;
    color: $n4;
    transition: color .2s;
    @include t {
        display: none; }
    @include m {
        display: flex;
        align-items: center;
        margin: 0 -32px;
        padding: 0 32px;
        height: 64px;
        @include poppins;
        font-size: 24px;
        font-weight: 600; }
    &:hover,
    &.active {
        color: $n2;
        @include m {
            background: none;
            box-shadow: inset 2px 0 0 $p1; }
        @include dark {
            color: $n6;
            @include m {
                background: none;
                color: $n6; } } } }

.language {
    margin-left: 40px;
    @include t {
        margin-left: 32px; }
    @include m {
        display: none; } }

.button {
    margin-left: 40px;
    @include t {
        margin-left: 32px; }
    @include m {
        width: 100%;
        height: 48px;
        margin: auto 0 0; }
    &.active {
        background: $n2;
        box-shadow: inset 0 0 0 2px $n2;
        color: $n8; } }

.notification,
.user,
.login {
    margin-left: 24px;
    @include m {
        margin-left: 20px; } }

.login {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $p4;
    transition: background .2s;
    svg {
        fill: $n8; }
    &:hover {
        background: darken($p4, 8); } }

.burger {
    display: none;
    @include m {
        display: block;
        position: relative;
        z-index: 15;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        &.active {
            &:before {
                transform: translateY(0) rotate(-45deg); }
            &:after {
                transform: translateY(0) rotate(45deg); } }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 16px;
            left: 6px;
            width: 20px;
            height: 2px;
            background: $n4;
            border-radius: 2px;
            transition: transform .2s; }
        &:before {
            transform: translateY(-4px); }
        &:after {
            transform: translateY(3px); } } }
