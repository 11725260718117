
.Toastify__toast {
    border-radius: 10px;
    color: $n2;
    @include poppins;
    @include dark-common {
        color: $n8;
        background-color: $n1; } }

.Toastify__close-button {
    @include dark-common {
        color: $n8;
        opacity: 0.8; } }

.Toastify__toast--warning {
    z-index: 100000; }

