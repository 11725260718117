@import "../../../styles/helpers";

.head {
    display: flex;
    align-items: center;
    margin-bottom: 3.5rem;
    @include d {
        margin-bottom: 64px; }
    @include m {
        display: block;
        margin-bottom: 32px; } }

.details {
    width: 100%;
    max-width: 540px;
    margin-right: auto; }

.button {
    @include m {
        width: 100%;
        margin-top: 32px; } }

.preview {
    position: relative;
    border-radius: 16px;
    max-height: 650px;
    overflow: hidden;
    display: flex;
    @include d {
        max-height: 500px; }
    img {
        width: 100%;
        object-fit: cover;
        // +m
        //     position: absolute
        //     top: 0
        //     left: 0
 } }        //     height: 100%

.title {
    margin-bottom: 1rem; }

.titleLink {
    color: $n1;
    @include dark {
        color: $n7; } }


.titleSkeleton {
    width: 100%;
    height: 1.6rem;
    border-radius: 99999px;
    margin-bottom: 1rem; }

.datesSkeleton {
    width: 70%;
    height: 1rem;
    border-radius: 99999px;
    margin-bottom: 0.6rem; }

.buttonSkeleton {
    min-width: 8rem; }
