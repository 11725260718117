@import "styles/helpers";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }

.title {
    text-transform: uppercase;
    margin-top: 4rem; }

.errorTitle {
    font-size: 10rem;
    color: $n6;
    @include dark {
        color: $n3; } }

.errorHeading {
    font-size: 1.5rem; }

.homeLink {
    margin-top: 1rem;
    font-size: 1rem;
    color: $n1;
    text-decoration: underline;
    @include dark {
        color: $n8; } }



