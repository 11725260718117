@import "styles/helpers";

.container {
    position: relative; }

.head {
    margin-bottom: 48px;
    // padding-right: 120px
    @include m {
        padding-right: 0; } }

.body {
    position: relative;
    max-width: 1024px;
    margin: 0 auto; }

.line {
    position: absolute;
    top: 66px;
    left: 50%;
    transform: translateX(-50%);
    @include t {
        top: 24px; }
    @include m {
        display: none; }
    @include dark {
        path {
            stroke: $n3 !important; } } }

.list {
    position: relative;
    z-index: 3;
    display: flex;
    @include m {
        display: block;
        max-width: 256px;
        margin: 0 auto; } }

.item {
    flex: 0 0 256px;
    text-align: center;
    @include t {
        flex: 0 0 200px; }
    &:not(:last-child) {
        margin-right: auto;
        @include m {
            margin: 0 0 64px; } } }

.preview {
    img {
        width: 100%; } }

.title {
    text-align: center; }

.subtitle {
    margin-bottom: 1rem;
    h4 {
        margin-bottom: 8px;
        @include body-bold-1; }
    p {
        color: $n5; } }

.text {
    color: $n4; }

.textMB {
    margin-bottom: 5rem; }

.titleWork {
    margin-bottom: 3rem; }

.top.sticky {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $n8;
    z-index: 100;
    box-shadow: 0px 0px 10px 0px $n6;
    @include dark {
        background: $n1;
        box-shadow: 0px 0px 15px 2px $n2; }
    .nav {
        margin-bottom: 0; } }

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px -6px 40px;
    padding-bottom: 0.4rem;
    @include m {
        display: none;
        padding-bottom: 0; } }

.btn {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover {
        color: $n3;
        @include dark {
            color: $n8; } }
    &.active {
        background: $n3;
        color: $n8;
        @include dark {
            background: $n8;
            color: $n2; } } }

.dropdown {
    display: none;
    text-align: left;
    @include m {
        display: block;
        margin-bottom: 10px; } }

.stickyDropdown {
    margin-top: 10px; }

.ulMargin {
    margin-left: 1rem; }

.btnSkeleton {
    min-width: 6rem;
    height: 1.8rem;
    margin: 0 1.5rem;
    border-radius: 99999px; }

.paragraphSkeleton {
    margin-bottom: 4rem; }

.paragraphSkeleton div {
    height: 1.1rem;
    margin-bottom: 1rem;
    border-radius: 99999px;

    &:nth-child(1) {
        width: 80%; }
    &:nth-child(2) {
        width: 60%; }
    &:nth-child(3) {
        width: 20%; }
    &:nth-child(4) {
        width: 70%; }
    &:nth-child(5) {
        width: 55%; } }

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 4.2rem; }

.stickySecondary {
    top: 67px; }

.containerStickyAdjustment {
    padding-top: 4.2rem; }

.noArticleHeading {
    font-size: 1.1rem;
    text-align: center; }
