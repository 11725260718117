@import "styles/helpers";

.head {
    display: flex;
    margin-bottom: 64px;
    @include m {
        margin-bottom: 32px; }
    .button {
        flex-shrink: 0;
        margin-left: 16px;
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } } }

.title {
    margin-right: auto;
    @include m {
        font-size: 32px; } }

.item {
    &:not(:last-child) {
        margin-bottom: 48px; } }

.category {
    margin-bottom: 32px;
    @include body-bold-2; }

.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.row {
    display: flex;
    align-items: flex-start;
    margin: 0 -10px;
    @include m {
        display: block;
        margin: 0; }
    &:not(:last-child) {
        margin-bottom: 32px; } }

.col {
    position: relative;
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 10px;
    @include m {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include m {
            margin-bottom: 20px; } }
    .button {
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 12px;
        font-size: 14px;
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } } }

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.controls {
    display: flex;
    align-items: center;
    margin-top: 48px;
    padding-top: 48px;
    margin-bottom: 4rem;
    .button {
        min-width: 8rem;
        margin-right: 40px; } }

hr {
    margin: 4.5rem 0;
    border-top: 1px solid $n6;
    @include dark {
        border-color: $n3; } }

.clear {
    display: flex;
    align-items: center;
    @include button-1;
    color: $n4;
    transition: color .2s;
    svg {
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $p1;
        svg {
            fill: $p1; } } }

.button {
    min-width: 10rem; }

.theme {
    display: inline-block;
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 0; }

.input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked + .inner .box {
        background: $p1;
        &:before {
            transform: translate(24px,-50%);
            background: $n8; } } }

.inner {
    position: relative;
    display: inline-block;
    transition: all .2s; }

.box {
    position: relative;
    display: block;
    width: 48px;
    height: 24px;
    background: $n6;
    border-radius: 12px;
    transition: all .2s;
    @include dark {
        background: $p1; }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $p1;
        transition: all .2s; } }

.newsletterContainer {
    display: flex;
    gap: 1rem; }

