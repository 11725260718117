@import "styles/helpers";

.label {
    margin-bottom: 12px;
    @include hairline-2;
    color: $n5; }

.wrap {
    position: relative; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $n6;
    background: none;
    @include poppins;
    @include caption-bold-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n8; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; }
    &.error {
        border-color: $e1; } }

.textArea {
    width: 100%;
    height: 88px;
    max-height: 200px;
    padding: 0.8rem 1rem;
    border-radius: 12px;
    border: 2px solid $n6;
    background: none;
    resize: vertical;
    @include poppins;
    @include caption-bold-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n8; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; }
    &.error {
        border-color: $e1; } }

.toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 48px;
    svg {
        fill: $n5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n4; } }
    &.active {
        svg {
            fill: $n1; } }
    @include dark {
        svg {
            fill: $n4; }
        &:hover {
            svg {
                fill: $n5; } }
        &.active {
            svg {
                fill: $n8; } } } }

.empty {
    .input {
        height: 26px;
        border: none; } }

.view {
    .input {
        padding-right: 48px; } }


.errorMessage {
    margin-top: 0.3rem;
    margin-left: 0.2rem;
    color: $e1;
    fill: $e1;
    svg {
        margin-right: 0.5rem; } }
