[class^="skeleton"] {
    background: $n7;
    background: linear-gradient(120deg, $n7 40%, $n8 50%, $n7 60%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    @include dark-common {
        background: $n2;
        background: linear-gradient(120deg, $n2 40%, $n3 50%, $n2 60%);
        background-size: 200% 100%; } }


@keyframes shine {
    to {
        background-position-x: -200%; } }
