@import "../../styles/helpers";

.title {
    margin: 4rem auto 2rem; }

.mapsContainer {
    width: 100%;
    height: 500px;
    margin-top: 3rem; }

.mapMarkerContainer {
    position: absolute;
    transform: translate(-90%, -90%);
    background: $n7;
    padding: 0.7rem;
    border-radius: 50%;
    box-shadow: 0px 0px 12px -8px $n2;
    @include dark {
        background: $n2; }
    &:hover {
        .address {
            display: block; } }

    .address {
        position: absolute;
        transform: translate(35%, 0%);
        top: 0;
        left: 0;
        min-width: 10rem;
        padding: 0.6rem;
        background: $n7;
        border-radius: 0.6rem;
        font-size: 0.9rem;
        display: none;
        @include dark {
            color: $n7;
            background: $n2; } }
    .locationMarker {
        width: 1.8rem;
        height: 1.8rem;
        @include dark {
            fill: $n7; } } }

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center; }

.loader {
    margin: 0 16px 0 5px;
    margin-top: 6rem;
    margin-bottom: 6rem; }


