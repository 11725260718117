@import "styles/helpers";

.top.sticky {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $n8;
    box-shadow: 0px 0px 10px 0px $n6;
    @include dark {
        background: $n1;
        box-shadow: 0px 0px 15px 2px $n2; }
    .nav {
        margin-bottom: 0; } }

.nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px -6px 40px;
    @include m {
        display: none; } }

.btn {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $n4;
    transition: all .2s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:hover {
        color: $n3;
        @include dark {
            color: $n8; } }
    &.active {
        background: $n3;
        color: $n8;
        @include dark {
            background: $n8;
            color: $n2; } } }

.dropdown {
    display: none;
    text-align: left;
    @include m {
        display: block;
        margin-bottom: 24px; } }

.stickyDropdown {
    margin-top: 24px; }

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.2rem;
    z-index: 9998; }


.stickySecondary {
    top: 67px; }

.containerStickyAdjustment {
    display: block;
    width: 100%;
    height: 4.2rem; }

.membership {
    text-align: center; }
