@import "../../styles/helpers";

.questionnaire_container {
    padding-top: 3rem;
    width: 450px;
    margin: 0 auto;
    .bigText {
        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-weight: 700;
        font-size: 3.6rem;
        line-height: 1;
        letter-spacing: -.02em;
        color: #141416;
        text-shadow: 0px 0px 2px #777E90;
        text-transform: uppercase;
        a {
            color: $n1;
            font-size: 58px;
            text-shadow: 0px 0px 3px #B1B5C3;
            @include dark {
                color: $n7; } } } }


.Q_stepperMain {
    position: relative;

    .Q_stepperContainer {
        display: flex;
        width: 450px;
        margin: 20px auto 0;
        justify-content: space-around;
        div {}
        p {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center; }
        .first {
            background: #4c4807;
            color: $white; }
        .second {
            background: $p1;
            color: $white; } }
    .line {
        height: 2px;
        background: grey;
        width: 50%;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1; } }
