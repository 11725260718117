@import "styles/helpers";

.registerSkeleton {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    margin-top: 2.25rem;
    @include m {
        max-width: 300px; }

    .skeletonInput {
        display: block;
        width: 100%;
        height: 45px;
        margin-bottom: 1rem;
        border-radius: 0.75rem;
        border: 2px solid $n6;
        @include dark {
            border: 1px solid $n3; } }


    .skeletonText {
        display: block;
        width: 100%;
        height: 1rem;
        margin-top: 2rem;
        border-radius: 999999px;
        margin-bottom: 4rem; }

    .skeletonButton {
        display: block;
        width: 100%;
        height: 45px;
        margin-bottom: 1rem;
        border-radius: 999999px; } }
