@import "../../../styles/helpers";

.section {
    // padding-bottom: 106px
    // border-bottom: 1px solid $n6
    // +d
    //     margin-bottom: 60px
    //     padding-bottom: 60px
    // +m
    //     margin-bottom: 54px
    //     padding-bottom: 54px
    @include dark {
        border-color: $n2; } }

.wrapper {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.details {
    flex-grow: 1;
    padding-right: 48px;
    @include t {
        margin-bottom: 48px;
        padding: 0; } }

.receipt {
    flex-shrink: 0;
    @include m {
        margin: 0 -16px; } }

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:not(:last-child) {
        margin-bottom: 32px; } }

.category {
    margin-bottom: 12px;
    @include body-bold-1; }

.item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.wrap {
    flex-grow: 1; }

.subtitle {
    margin-bottom: 8px;
    @include body-bold-2; }

.content {
    @include caption-2;
    color: $n4; }

.counter {
    flex-shrink: 0;
    width: 128px;
    margin-left: 24px;
    padding: 12px;
    border-radius: 24px;
    box-shadow: inset 0 0 0 1px $n6;
    @include dark {
        box-shadow: inset 0 0 0 1px $n3; } }

.table {
    margin-bottom: 32px; }

.line {
    display: flex;
    padding: 8px 12px;
    &:last-child {
        background: $n7;
        border-radius: 8px;
        @include dark {
            background: $n3; }
        .cell {
            &:first-child {
                color: $n2;
                @include dark {
                    color: $n8; } } } } }

.cell {
    &:first-child {
        margin-right: auto;
        color: $n4; }
    &:nth-child(2) {
        flex-shrink: 0;
        margin-left: 20px;
        text-align: right;
        font-weight: 500; } }

.foot {
    text-align: center; }

.note {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    @include poppins;
    @include caption-2;
    color: $n4;
    svg {
        margin-right: 8px;
        fill: $n4; } }

.button {
    min-width: 10rem; }

.modalBody {
    display: flex;
    align-items: center;
    justify-content: center; }

.icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    &.success {
        fill: $p4; }
    &.warning {
        fill: $s3; } }

.optionContainer {
    display: flex;
    justify-content: center;
    padding: 15px;
    background: $n7;
    border-radius: 20px;
    @include dark {
        background: #353945; } }

.optionAction {
    text-align: center;
    display: flex;
    align-items: center; }



input[disabled] {
    background-color: $p1;
    &:hover {
        accent-color: $p1;
        cursor: not-allowed; } }

table {
    border: 1px solid white;
    border-radius: 20px;
    tr {
        border: 1px solid white;
        td {
            border: 1px solid white;
            padding: 8px;
            text-align: center; }
        th {
            border: 1px solid white;
            padding: 8px;
            text-align: center; } } }

.totalPriceContainer {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    .totalPrice {
        padding: 15px;
        background: $n7;
        border-radius: 20px;
        @include dark {
            background: #353945; } } }

.checkbox {
    display: inline-block;
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0); }

.input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked + .inner .tick {
        background: $p1;
        border-color: $p1;
        &:before {
            opacity: 1; } }

    &:disabled + .inner .tick {
        background: grey;
        border-color: grey;
        cursor: not-allowed; }
    &:disabled {
        &:checked + .inner .tick {
            background: grey;
            border-color: grey;
            cursor: not-allowed;
            &:before {
                opacity: 1;
                border-color: grey; } } } }

.inner {
    display: flex;
    &:hover {
        .tick {
            border-color: $p1; } } }

.tick {
    position: relative;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 4px;
    border: 2px solid $n6;
    transition: all .2s;
    @include dark {
        background: $n1;
        border-color: $n3; }
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 14px;
        height: 10px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        opacity: 0;
        transition: opacity .2s; } }

.text {
    line-height: (24/14);
    font-weight: 500;
    color: $n2;
    @include dark {
        color: $n8; } }

.note {
    flex-shrink: 0;
    margin-left: auto;
    padding-left: 16px;
    font-weight: 500;
    color: $n4; }

.reFactortitle {
    display:flex !important {}
    flex-direction: column;
    justify-content: center;
    align-items: center; }
