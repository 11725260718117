@import "styles/helpers";

.titleHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;
    @include m {
        margin-bottom: 32px; } }

.title {
    // margin-bottom: 64px
    @include m {
        // margin-bottom: 32px
        font-size: 32px; } }

.box {
    &:not(:last-child) {
        margin-bottom: 64px;
        padding-bottom: 64px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.category {
    margin-bottom: 16px;
    @include body-bold-1; }

.item {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.head {
    display: flex;
    margin-bottom: 4px; }

.subtitle {
    margin-right: auto;
    @include body-bold-2; }

.switch {
    flex-shrink: 0;
    margin-left: 24px; }

.content {
    @include caption-2;
    color: $n4; }


.fieldset {
    & > .field {
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.row {
    display: flex;
    // margin: 0 -10px
    @include m {
        display: block;
        margin: 0; }
    &:not(:last-child) {
        margin-bottom: 32px; } }

.col {
    position: relative;
    flex: 0 0 calc(50% - 20px);
    width: calc(50% - 20px);
    margin: 0 10px;
    @include m {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include m {
            margin-bottom: 20px; } }
    .button {
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 12px;
        font-size: 14px;
        @include dark {
            box-shadow: inset 0 0 0 2px $n3; } } }

.controls {
    display: flex;
    align-items: center;
    margin-top: 48px;
    padding-top: 48px;
    margin-bottom: 4rem;
    .button {
        min-width: 8rem;
        margin-right: 40px; } }

.fieldCol {
    margin-right: 1rem;
    @include m {
        margin-right: 0;
        margin-bottom: 1rem; } }

.addButton {
    padding: 0.5rem 0.8rem;
    border-radius: 999999px;
    color: $n7;
    background: $n3;
    font-size: 0.7rem;
    font-weight: 500;
    &:disabled {
        color: $n5;
        background: $n2;
        cursor: not-allowed; }
    @include dark {
        background: $n3;
        &:disabled {
            color: $n4;
            background: $n2; } } }

.removeButton {
    margin-top: 2.1rem;
    border-radius: 50%;
    color: $n7;
    background: $e1;
    svg {
        fill: $n8; }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6; }
    @include m {
        margin-top: 0;
        display: block;
        margin-left: auto; } }
