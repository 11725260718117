@import "../../../styles/helpers";

.title {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 32px;
        font-size: 32px; } }

.box {
    &:not(:last-child) {
        margin-bottom: 64px;
        padding-bottom: 64px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.category {
    margin-bottom: 16px;
    @include body-bold-1; }

.item {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.head {
    display: flex;
    margin-bottom: 4px; }

.subtitle {
    margin-right: auto;
    @include body-bold-2; }

.switch {
    flex-shrink: 0;
    margin-left: 24px; }

.content {
    @include caption-2;
    color: $n4; }

.categoryTitle {
    margin-bottom: 1rem;
    @include body-bold-2; }

.question {
    margin-bottom: 1rem; }

.questionText {
    margin-bottom: 0.3rem; }

.submitedTitle {
    margin-top: 5rem; }

.button {
    min-width: 10rem;
    margin: 3rem 0px; }

.skeletonContainer {
    margin-top: 3rem; }

.questionnaireHeading {
    font-size: 1.1rem;
    font-weight: 500; }

.subTitles {
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    display: block;
    margin-bottom: 10px; }

.questionsContainer {
    padding: 1rem 1.4rem;
    background: $n7;
    border-radius: 16px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    box-shadow: inset 0px 0px 1px $n6;
    @include dark {
        background: $n2;
        box-shadow: none; } }

.questionContainer {
    margin-top: 1.5rem;
    &:first-child {
        margin-top: 0; } }

.questionLabel {
    margin-bottom: -1rem; }

.checkbox_container {
    display: flex; }

.labelRequired {
    color: red; }

.bigText {
    text-align: center;
    margin: 2rem auto 1rem;
    font-size: 40px;
    font-weight: 600; }

.button_2 {
    width: 100%;
    margin-bottom: 20px; }





