@import "styles/helpers";

.inner {
    overflow: hidden; }
.loadingContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
        text-align: center;
        margin: 0 3rem;
        margin-bottom: 16px;
        color: $n1;
        text-shadow: 0px 0px 13px $n5;
        text-transform: uppercase;
        @include dark {
            color: $n7;
            text-shadow: 0px 0px 12px $n4; } }
    .loader {
        margin: 0 16px 0 5px;
        transform: scale(1.4);
        margin-top: 2rem;
        margin-bottom: 2rem; } }
