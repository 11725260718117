@import "../../../styles/helpers";

.section {
    margin-bottom: 56px !important;
    @include d {
        margin-bottom: 56px !important; }
    @include m {
        margin-bottom: 34px !important; } }

.container {
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include t {
        padding: 0; }
    @include m {
        padding: 0 8px; } }

.previewContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 64px;
    margin-bottom: 64px;
 }    // height: 100vh;

.preview {
    width: 100%;
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-bottom: 60.63%;
        @include d {
            padding-bottom: 64%; }
        @include m {
            display: none; } }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        object-fit: cover;
        @include t {
            border-radius: 0; }
        @include m {
            position: static;
            height: 780px;
            border-radius: 16px;
            object-position: 50% 100%; } } }

.wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 145px 80px;
    @include d {
        padding: 98px 80px; }
    @include t {
        padding: 80px 40px; }
    @include m {
        padding: 80px 12px;
        text-align: center; } }

.title {
    max-width: 450px;
    margin-bottom: 16px;
    color: $n7;
    text-shadow: 0px 0px 3px $n5; }

.capitalHeading {
    text-transform: uppercase; }

.info {
    margin-bottom: 24px;
    color: $n2;
    @include d {
        font-size: 16px; } }

.panel {
    margin: -175px 80px 0;
    @include w {
        margin: -175px 40px 0; }
    @include d {
        margin-top: -148px; }
    @include t {
        margin: -110px 16px 0; }
    @include m {
        margin: -180px 8px 0; } }

.body {
    margin-top: 24px;
    @include d {
        margin-top: 16px; } }

.row {
    display: flex;
    @include x {
        margin: 0 -20px; }
    @include t {
        margin: 0; }
    @include m {
        display: block; }
    & > div {
        &:first-child {
            flex: 0 0 300px;
            @include w {
                flex: 0 0 270px; }
            @include d {
                flex: 0 0 250px; }
            @include t {
                flex: 0 0 220px; } }
        &:nth-child(2) {
            flex-grow: 1; }
        &:nth-child(3) {
            flex: 0 0 296px;
            @include d {
                flex: 0 0 240px; }
            @include t {
                flex: 0 0 170px; } } } }
