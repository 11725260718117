@import "styles/helpers";


.login {
    width: 450px;
    margin: 0 auto;
    @include m {
        width: 300px; } }

.field {
    margin-bottom: 1rem; }

.item {
    display: none;
    &:first-child {
        display: block; } }

.title {
    text-align: center;
    margin: 2rem auto 1rem; }

.info {
    margin-top: 12px;
    text-align: center;
    @include body-2;
    color: $n4; }

.btns {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .button {
        &:not(:last-child) {
            margin-right: 12px; } } }

.note {
    margin-top: 32px;
    text-align: center;
    @include caption-2;
    color: $n4; }

.form {
    margin-top: 32px;
    & > .button {
        width: 100%;
        margin-top: 12px; } }

.foot {
    margin-top: 32px;
    text-align: center;
    @include caption-2;
    color: $n3;
    @include dark {
        color: $n6; } }

.link,
.password {
    font-weight: 600;
    transition: color .2s; }

.link {
    color: $p1;
    &:hover {
        color: darken($p1, 10); } }

.password {
    color: $n3;
    &:hover {
        color: $n1; }
    @include dark {
        color: $n6;
        &:hover {
            color: $p1; } } }

.variants {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }

.radio {
    &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.code {
    display: flex;
    justify-content: center; }

.number {
    flex-shrink: 0;
    width: 48px;
    input {
        width: 100%;
        height: 64px;
        border-radius: 8px;
        border: 1px solid $n7;
        background: $n7;
        text-align: center;
        @include dm-sans;
        font-size: 32px;
        font-weight: 600;
        color: $n2;
        transition: all .2s;
        &:focus {
            border-color: $n6;
            background: $n8; }
        @include dark {
            background: $n2;
            border-color: $n2;
            color: $n8;
            &:focus {
                background: none;
                border-color: $n3; } } }
    &:not(:last-child) {
        margin-right: 16px; } }

.error {
    color: $e1;
    fill: $e1;
    margin-bottom: 2rem; }

.checkbox {
    margin-top: 1rem;
    margin-bottom: 3rem; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $n6;
    background: none;
    margin-bottom: 1rem;
    @include poppins;
    @include caption-bold-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n4; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; }
    &.error {
        border-color: $e1; } }

.input_select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 10px;
    border-radius: 2px;
    margin-right: 2rem;
    width: 100%;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $n6;
    margin-bottom: 1rem;
    padding-right: 3rem;
    @include poppins;
    @include caption-bold-1;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n4;
        background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"); }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; }
    &.error {
        border-color: $e1; } }


