@import "styles/helpers";

.card {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    overflow: hidden;
    background: $n8;
    border: 1px solid $n6;
    color: $n1;
    transition: border-color .2s;

    &:hover {
        border-color: $n5;
        .preview {
            img {
                width: 120%;
                height: 120%;
                top: -10%;
                left: -10%; } } }

    @include dark {
        background: $n1;
        border-color: $n3;
        color: $n8; }
    &.row {
        flex-direction: row;
        border-radius: 16px;
        @include x {
            display: block; }
        .preview {
            flex-shrink: 0;
            width: 256px;
            @include x {
                width: 100%; }
            &:before {
                display: none;
                @include x {
                    display: block; } } }
        .body {
            display: flex;
            flex-direction: column;
            flex-grow: 1; }
        .line {
            margin-bottom: 16px; }
        .price {
            display: flex;
            flex-direction: column-reverse; }
        .old {
            margin: 6px 0 0;
            text-decoration: none; }
        .options {
            flex-wrap: nowrap;
            @include x {
                flex-wrap: wrap; } }
        .option {
            &:nth-child(2) {
                flex-shrink: 0; } }
        .foot {
            margin-top: auto; } }
    &.car {
        .line {
            margin-bottom: 0;
            @include d {
                margin-bottom: 8px; } }
        .price {
            display: flex;
            flex-direction: column-reverse; }
        .old {
            margin: 6px 0 0;
            text-decoration: none; }
        .option {
            &:nth-child(2) {
                flex-shrink: 0; } } } }

.preview {
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-bottom: 68.6%; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease; } }


.category {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2; }

.like {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    button {
        background: $n8; } }

.body {
    flex-grow: 1;
    padding: 24px 22px; }

.line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px; }

.title {
    @include body-bold-2; }

.price {
    white-space: nowrap;
    margin-left: 16px;
    padding: 6px 8px;
    box-shadow: inset 0 0 0 2px $p4;
    border-radius: 4px;
    text-align: center;
    @include hairline-2;
    color: $p4; }

.old {
    margin-bottom: 6px;
    text-decoration: line-through;
    color: $n5; }

.date {
    margin-right: 20px;
    @include caption-2;
    color: $n4; }

.options {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 16px -12px; }

.option {
    display: flex;
    align-items: center;
    margin: 8px 0 0 12px;
    @include caption-2;
    color: $n4;
    overflow: hidden;
    svg {
        flex-shrink: 0;
        margin: 1px 6px 0 0;
        fill: $n4; }
    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; } }

.foot {
    padding-top: 16px;
    border-top: 1px solid $n6;
    @include dark {
        border-color: $n2; } }

.comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    @include caption-2;
    color: $n4; }

.avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.flex {
    display: flex;
    justify-content: space-between; }

.cost {
    @include caption-bold-2; }

.tickets {
    display: flex;
    align-items: flex-start;
    svg {
        margin-right: 0.6rem;
        fill: $s3; } }

.ticketStats {
    display: flex;
    flex-direction: column; }

.number {
    margin-right: 4px;
    text-align: right;
    @include caption-bold-2; }

.review {
    @include caption-2;
    color: $n4; }


